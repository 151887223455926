import Rails from '@rails/ujs';
Rails.start();

import '../controllers';

import { cable } from '@hotwired/turbo-rails';
import * as Turbo from '@hotwired/turbo';
Turbo.start();
// Disable Turbo Drive everywhere
// It will only be used when data-turbo or data-turbo-stream is present
Turbo.session.drive = false;
